import React, { useEffect, useRef, useState } from "react";

import { RootState, useAppDispatch } from "../../redux/store";

import { redirect, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function SoepayComponenet({
  price,
  redirectToPaymentGateway,
}: {
  price: number;
  redirectToPaymentGateway: any;
}) {
  const initialOptions = {
    clientId:
      "AcHmEW25jmbJDjyTz5J6gdxGN5OM-ZbHjJGfl04AmLwDo0usHqJP3y4RlKDvxWYJOwC6aXBH_hnnF85X",
    currency: "HKD",
  };
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const [message, setMessage] = useState("");
  const [orderID, setOrderID] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createOrder = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/payment-service/soepay/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          currency: "HKD",
          hppConfig: {
            lang: "zh",
            quickPay: "true",
            redirect: window.location.host + "/redirectfrompayment",
          },
          products: [
            {
              name: "Bonni Travel",
              price: price,
              quantity: 1,
            },
          ],
          total: price,
          billingInfo: {
            name: currentUser?.displayName,
            address1: "HK",
            city: "HK",
            postalCode: "000000",
            state: "Hk",
            country: "HK",
            email: currentUser?.email,
          },
        }),
      });

      const responseJson = await response.json();

      if (
        responseJson?.data?.id &&
        responseJson?.data?.invoice?.paymentLinkUrl
      ) {
        setOrderID(responseJson?.data?.id);
        redirectToPaymentGateway(
          responseJson?.data?.id,
          responseJson?.data?.invoice?.paymentLinkUrl
        );
      } else {
        throw new Error("Fail to create order for Soepay");
      }
    } catch (error) {
      console.error(error);
      setMessage(`Could not initiate Soepay Checkout...${error}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button variant="warning" onClick={createOrder}>
        {loading ? (
          <Spinner animation="border" variant="warning" />
        ) : (
          "前去付款頁面"
        )}
      </Button>
    </>
  );
}
